<template>
  <div class="app-body">
    <bread-crumb></bread-crumb>
    <el-card class="el-main deviceAdd">
      <el-form ref="deviceAddForm" :model="form" :rules="rules">
        <div class="a-fs-16 a-fw-700">设备信息</div>
        <div class="a-flex-cfsfs">
          <el-form-item class="a-mt-32" prop="deviceType">
            <le-select-remote-search
              v-model="form.deviceType"
              class="editDevType"
              notnull
              label="设备类型"
              :options="optionsDeviceType"
              placeholder="请选择（可搜索）"
            />
          </el-form-item>
          <el-form-item prop="protocolType">
            <le-select-local-search
              v-model="form.protocolType"
              notnull
              label="协议类型"
              :options="COMMON_CONST.protocolTypeList"
            ></le-select-local-search>
          </el-form-item>
          <el-form-item prop="slotNum">
            <le-select-local-search
              v-model="form.slotNum"
              notnull
              label="插槽数"
              :options="SLOT_NUMS"
            ></le-select-local-search>
          </el-form-item>
          <el-form-item prop="deviceCode">
            <le-input
              v-model="form.deviceCode"
              notnull
              class="editDevType"
              label="设备编码"
              :maxlength="15"
              placeholder="请输入"
            ></le-input>
          </el-form-item>
          <el-form-item prop="imei">
            <le-input
              v-model="form.imei"
              notnull
              class="editDevType"
              label="IMEI/厂商ID"
              :maxlength="15"
              placeholder="请输入"
            ></le-input>
          </el-form-item>
          <el-form-item prop="stationId">
            <le-select-local-search
              v-model="form.stationId"
              class="editDevType"
              label="绑定站点"
              :options="stationList"
              placeholder="请选择站点"
            />
          </el-form-item>
          <el-form-item prop="serialNumber">
            <le-input
              v-model="form.serialNumber"
              label="序列号"
              class="editDevType"
              :maxlength="16"
              placeholder="请输入"
            ></le-input>
          </el-form-item>
          <el-form-item prop="iccid">
            <le-input
              v-model="form.iccid"
              label="ICCID"
              class="editDevType"
              :maxlength="20"
              placeholder="请输入"
            ></le-input>
          </el-form-item>
          <el-form-item prop="canUse">
            <le-switch
              v-model="form.canUse"
              class="editDevType"
              label="设备状态"
              actext="启用"
              inactext="禁用"
              :ac-value="1"
              :inac-value="0"
            ></le-switch>
          </el-form-item>
        </div>
        <div style="height: 100px"></div>
        <div class="a-line-t-e0 footerBox">
          <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">确定</el-button>
          <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancel">
            取消
          </el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { COMMON_CONST } from "@charge/common";

import { companyStationApi } from "@/services/apis/company/station";

export default {
  name: "DeviceEdit",

  data() {
    return {
      COMMON_CONST,
      deviceCode: "",
      SLOT_NUMS: [
        {
          label: "12个",
          value: 12,
        },
        {
          label: "10个",
          value: 10,
        },
        {
          label: "2个",
          value: 2,
        },
      ],
      optionsDeviceType: {
        url: this.$Config.apiUrl.getDeviceType,
        method: "post",
        params: {},
        labelKey: "name",
        valueKey: "id",
        searchKey: "key",
      },
      stationList: [],
      form: {
        deviceType: "",
        protocolType: "",
        slotNum: "",
        deviceCode: "",
        imei: "",
        stationId: "",
        serialNumber: "",
        iccid: "",
        canUse: 1,
      },
      rules: {
        deviceType: [{ required: true, message: "请选择设备类型", trigger: "change" }],
        protocolType: [{ required: true, message: "请选择协议类型", trigger: "change" }],
        slotNum: [{ required: true, message: "请选择设备插槽数", trigger: "change" }],
        deviceCode: [{ required: true, message: "请输入设备编码", trigger: "blur" }],
        imei: [{ required: true, message: "请输入IMEI/厂商ID", trigger: "blur" }],
      },
    };
  },

  created() {
    this.getStationList();
  },

  activated() {
    const deviceCode = this.$route.query.deviceCode;
    if (deviceCode && deviceCode !== this.form.deviceCode) {
      this.form.deviceCode = deviceCode;
      this.getDevInfo();
    }
  },

  methods: {
    async getStationList() {
      const list = await companyStationApi.selectList();
      this.stationList = list.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    },

    getDevInfo() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.queryDeviceInfo,
          method: "get",
          params: {
            deviceCode: this.form.deviceCode,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.form = res.result.data;
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    submit() {
      this.$refs["deviceAddForm"].validate((valid) => {
        if (valid) {
          this.$Axios
            ._post({
              url: this.$Config.apiUrl.editDevice,
              method: "post",
              params: this.form,
            })
            .then((res) => {
              if (res.result.code == 0) {
                this.$message.success("编辑成功");
                this.$router.push("/device/device-list");
                this.$store.dispatch("delView", this.$route);
              } else {
                this.$message.error(res.result.message);
              }
            });
        }
      });
    },
    cancel() {
      this.$router.push("/device/device-list");
      this.$store.dispatch("delView", this.$route);
    },
  },
};
</script>

<style lang="scss">
.deviceAdd {
  padding-bottom: 100px;
  .el-form-item__error {
    left: 110px !important;
  }
}

.editDevType {
  padding: 0 0 6px 0 !important;
}
</style>

<style lang="scss" scoped>
/deep/ .s-search-label {
  width: 92px !important;
  justify-content: flex-start;
}
</style>
